import React from "react";
import InfoCard from "../components/Elements/InfoCard";

import TopNavbar from "../components/Nav/TopNavbar";
import logoWithText from "../assets/img/red_team.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Footer from "../components/Sections/Footer"

export const RedTeam = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const texts = [
        t('red_team_title1'),
        t('red_team_paragraph1'),
        t('red_team_title2'),
        t('red_team_paragraph2'),
        t('red_team_title3'),
        t('red_team_paragraph3'),
        t('red_team_title4'),
        t('red_team_paragraph4'),
        t('red_team_parahrapg5'),
    ]
    return (
        <>
            <TopNavbar />
            <div id="home" ></div>
            <InfoCard  title={t('red_team_services')} imageSrc={logoWithText} texts={texts}/>
            <div style={{paddingTop:"200px"}}></div>
            <Footer/>
        </>
    );
}
