import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import logoWithText from "../../assets/img/logo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import UK from "../../assets/svg/Flag_of_United_Kingdom.svg";
import RO from "../../assets/svg/Flag_of_Romania.svg";
import { useTranslation } from 'react-i18next';

import './TopNavbar.css';

export default function TopNavbar() {
  const [sidebarOpen, toggleSidebar] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg">
        <NavInner className="container">
          <NavLink exact="true" to="/"> 
            <Logo src={logoWithText} alt=""/>
          </NavLink>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer" style={styles.listItems}>
              <NavLink  className={({ isActive }) => (isActive ? 'active-nav link-item' : 'inactive link-item')} exact="true" to="/">
                {t(`home`)}
              </NavLink>
            </li>
            <li className="semiBold font15 pointer" style={styles.listItems}>
              <NavLink  className={({ isActive }) => (isActive ? 'active-nav link-item' : 'inactive link-item')} to="/about">
                {t(`about`)}
              </NavLink>
            </li>
            <li className="semiBold font15 pointer" style={styles.listItems}>
              <NavLink  className={({ isActive }) => (isActive ? 'active-nav link-item' : 'inactive link-item') } to="/services">
                {t(`services`)}
              </NavLink>
            </li>
            <li className="semiBold font15 pointer" style={styles.listItems}>
              <NavLink  className={({ isActive }) => (isActive ? 'active-nav link-item' : 'inactive link-item')} to="/contact">
                {t(`contact_link`)}
              </NavLink>
            </li>
          </UlWrapper>
        </NavInner>
        <div className="language_selector-container">
              <div href="" className="lang-switch" onClick={() => changeLanguage('en')}>
                <img src={UK} alt=""/>
              </div>
              <div href="" className="lang-switch" onClick={() => changeLanguage('ro')}>
                <img src={RO} alt=""/>
              </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  background: var(--background);
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding-right: 0px;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  height: 100%;
  @media (max-width: 760px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 175px;
  height: 50px;
  border-radius: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.7rem;
  background-color: var(--primary-200);
`;

const styles = {
  listItems: {
    height: "100%",
    display: "flex",
  },
};
