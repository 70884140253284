import React from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import logoWithText from "../../assets/img/logo.png";
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope} from "react-icons/fa"; 
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link to="/" className="flexCenter animate pointer"> {/* Link to Home page */}
              <Logo src={logoWithText} alt="Logo"/>
            </Link>
              <SocialIcons>
                <h1 className="font15 extraBold whiteColor" style={{ margin: "0 10px", color: "var(--text)" }}>
                  contact@nmiguard.com
                </h1>
                <FaEnvelope size={24} style={styles.email}/>
                <a href="https://www.linkedin.com/company/nmi-guard/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={24} /></a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram size={24} /></a>
                <a href="https://www.facebook.com/profile.php?id=61564916493541" target="_blank" rel="noopener noreferrer"><FaFacebook size={24} /></a>
              </SocialIcons>
              <ScrollLink className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80} style={{ marginLeft: "15px", color: "var(--text)" }}>
                {t(`back_to_top`)}
              </ScrollLink>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const Logo = styled.img`
  width: 175px;
  height: 50px;
  border-radius: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.7rem;
  background-color: var(--primary-200);
`;
const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  a {
    color: var(--text);
    margin: 0 10px;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary);
    }
  }
`;

const styles = {
  email: {
    margin: "0 10px",
  },
};
