import React from 'react';

export const NotFound = () => {
    console.log("asdfasdfasdf");
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>404</h1>
            <p style={styles.text}>Oops! The page you're looking for doesn't exist.</p>
        </div>
    );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '10%',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    fontSize: '96px',
    marginBottom: '20px',
    color: '#ff6b6b',
  },
  text: {
    fontSize: '24px',
    color: '#555',
  },
};

export default NotFound;