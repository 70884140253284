import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, id }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
      id={id}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  background-color: var(--accent);
  border-color: var(--accent);
  width: 100%;
  padding: 15px;
  outline: none;
  color: white;
  transition: transform 0.3s;
  :hover {
    background-color: #d96060;
  }
  :disabled {
    background: border-box;
  }
`;

