import React from "react";
import InfoCard from "../components/Elements/InfoCard";

import TopNavbar from "../components/Nav/TopNavbar";
import logoWithText from "../assets/img/audit.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Footer from "../components/Sections/Footer"

export const EuAiAudits = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const texts = [
        t('ai_act_title1'),
        t('ai_act_paragraph1'),
        t('ai_act_title2'),
        t('ai_act_paragraph2'),
        t('ai_act_title3'),
        t('ai_act_paragraph3'),
        t('ai_act_title4'),
        t('ai_act_paragraph4'),
        t('ai_act_parahrapg5'),
    ]
    return (
        <>
            <TopNavbar />
            <div id="home" ></div>
            <InfoCard  title={t('eu_ai_act')} imageSrc={logoWithText} texts={texts}/>
            <div style={{paddingTop:"200px"}}></div>
            <Footer/>
        </>
    );
}
