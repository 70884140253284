import React from "react";
import InfoCard from "../components/Elements/InfoCard";

import TopNavbar from "../components/Nav/TopNavbar";
import logoWithText from "../assets/img/audit.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Footer from "../components/Sections/Footer"

export const About = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <TopNavbar />
            <div id="home" ></div>
            <InfoCard  title={t('who_are_we')} imageSrc={logoWithText} texts={["",t('who_are_we_text')]}/>
            <div style={{paddingTop:"200px"}}></div>
            <Footer/>
        </>
    );
}
