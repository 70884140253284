import {BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {About} from "./pages/about";
import {LandingPage} from "./pages/landing";
import {ServicesPage} from "./pages/services";
import {Contact} from "./pages/contact";
import {EuAiAudits} from "./pages/eu_ai_audits";
import {RedTeam} from "./pages/ai_red_team_services";
import {Consulting} from "./pages/ai_security_consulting";
import {NotFound} from "./pages/404";

export const Routing = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/services" element={<ServicesPage/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/eu_ai_act" element={<EuAiAudits/>}/>
                <Route path="/ai_security_consulting" element={<Consulting/>}/>
                <Route path="/ai_red_team" element={<RedTeam/>}/>
                <Route path="/services/eu_ai_act" element={<EuAiAudits/>}/>
                <Route path="/services/ai_security_consulting" element={<Consulting/>}/>
                <Route path="/services/ai_red_team" element={<RedTeam/>}/>
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </Router>
    )
}