import React from "react";
import InfoCard from "../components/Elements/InfoCard";

import TopNavbar from "../components/Nav/TopNavbar";
import logoWithText from "../assets/img/security.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Footer from "../components/Sections/Footer"

export const Consulting = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const texts = [
        t('consulting_title1'),
        t('consulting_paragraph1'),
        t('consulting_title2'),
        t('consulting_paragraph2'),
        t('consulting_title3'),
        t('consulting_paragraph3'),
        t('consulting_title4'),
        t('consulting_paragraph4'),
        t('consulting_parahrapg5'),
    ]
    return (
        <>
            <TopNavbar />
            <div id="home" ></div>
            <InfoCard  title={t('ai_security_consultation')} imageSrc={logoWithText} texts={texts}/>
            <div style={{paddingTop:"200px"}}></div>
            <Footer/>
        </>
    );
}
