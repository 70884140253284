import React from "react";
import {Routing} from "./Routes"
import { Helmet } from "react-helmet";
import './i18n';

export default function App() {
  return (
    <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      <meta name="description" content="Ensure compliance with the EU AI Act in Romania with NMI Guard's expert audit services. Our comprehensive AI audits help businesses meet regulatory standards, ensuring transparency, accountability, and safety in AI deployments." />
    </Helmet>
    <Routing />
    </>
  );
}

