import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import logoWithText from "../../assets/img/logo.png";
import { useTranslation } from 'react-i18next';

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <Logo src={logoWithText} alt=""/>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>
      <SidebarContainer>
        <UlStyle className="flexNullCenter flexColumn">
          <li className="semiBold font15 pointer">
            <NavLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              className={({ isActive }) => (isActive ? 'active-sidebar whiteColor' : 'inactive whiteColor')}
              style={{ padding: "10px 15px" }}
              to="/"
              exact="true"
            >
              {t(`home`)}
            </NavLink>
          </li>
          <li className="semiBold font15 pointer">
            <NavLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              className={({ isActive }) => (isActive ? 'active-sidebar whiteColor' : 'inactive whiteColor')}
              style={{ padding: "10px 15px" }}
              to="/about" 
            >
              {t(`about`)}
            </NavLink>
          </li>
          <li className="semiBold font15 pointer">
            <NavLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              className={({ isActive }) => (isActive ? 'active-sidebar whiteColor' : 'inactive whiteColor')}
              style={{ padding: "10px 15px" }}
              to="/services"
            >
              {t(`services`)}
            </NavLink>
          </li>
          <li className="semiBold font15 pointer">
            <NavLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              className={({ isActive }) => (isActive ? 'active-sidebar whiteColor' : 'inactive whiteColor')}
              style={{ padding: "10px 15px" }}
              to="/contact"
            >
              {t(`contact_link`)}
            </NavLink>
          </li>
        </UlStyle>
      </SidebarContainer>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  height: 100%;
  position: fixed;
  background: linear-gradient(to bottom, #f8ae40, #371937);
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  z-index: 9999;
  @media (max-width: 200px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 175px;
  height: 50px;
`;
