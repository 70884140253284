import React from "react";
import styled from "styled-components";
// Assets
import GuyPresenting from "../../assets/img/guypresenting.png";
import RedGuy from "../../assets/img/redguy.png";
import CheckboxImage from "../../assets/img/checkboxicon.png";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router-dom";

export default function ServiceBox({icon, title, button, page}) {
  let getIcon;
  const navigate = useNavigate();
  switch (icon) {
    case "checkbox":
      getIcon = <img src={CheckboxImage}  style={{width:"45%"}} alt="Checkbox image for NMI Guard, EU AI ACT Audits"/>;
      break;
    case "guy-presenting":
      getIcon = <img src={GuyPresenting}  style={{width:"45%"}} alt="Service image for NMI Guard, EU AI ACT Audits"/>;
      break;
    case "red-guy":
      getIcon = <img src={RedGuy}  style={{width:"45%"}} alt="Service image for NMI Guard, EU AI ACT Audits"/>;
      break;
    default:
      getIcon = <img src={CheckboxImage}   style={{width:"45%"}}alt="Checkbox image for NMI Guard, EU AI ACT Audits"/>;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <FullButton title={button}  action={() => navigate(page)}/>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 2.5rem;
    gap: 20px;
    background-color: var(--primary-20);
    border-radius: 1rem;
`;
const IconStyle = styled.div`
text-align:center;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  white-space: pre-wrap;

`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;