import React from "react";
import InfoCard from "../components/Elements/InfoCard";

import TopNavbar from "../components/Nav/TopNavbar";
import logoWithText from "../assets/img/logo.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Services from "../components/Sections/Services";
import Footer from "../components/Sections/Footer"

export const ServicesPage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <TopNavbar />
            <div id="home" ></div>
            <div style={{paddingTop:"100px"}}></div>
            <Services/>
            <Footer/>
        </>
    );
}
