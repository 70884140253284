import React from "react";
import styled from "styled-components";
import TopNavbar from "../components/Nav/TopNavbar";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Footer from "../components/Sections/Footer";
import ContactSection from "../components/Sections/Contact";

export const Contact = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <TopNavbar />
            <ContactWrapper >
                <ContactSection />
            </ContactWrapper>
            <Footer/>
        </>
    );
}

const ContactWrapper = styled.div`
 {  
    display: flex;
    margin-top: 100px;
    width: 100%;
    justify-content: center;
 }
`;