import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import YoungMinds from "../../assets/img/minds.png";

export default function Services() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper id="services">
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold" style={styles.h1}>{t(`our_services`)}</h1>
        </HeaderInfo>
        <ServiceBoxRow className="flex">
          <ServiceBoxWrapper>
            <ServiceBox
              icon="checkbox"
              title={t('eu_ai_act')}
              button={t('read_more')}
              page="eu_ai_act"
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="guy-presenting"
              title={t('ai_security_consultation')}
              button={t('read_more')}
              page="ai_security_consulting"
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="red-guy"
              title={t('red_team_services')}
              button={t('read_more')}
              page="ai_red_team"
            />
          </ServiceBoxWrapper>
        </ServiceBoxRow>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <Img className="radius8" src={YoungMinds} alt="office" style={{zIndex: 9}} />
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">{t('a_few_words')}</h4>
              <h2 className="font40 extraBold" style={{paddingBottom:"20px"}}>{t('title_about_us_catchphrase')}</h2>
              <p className="font12">
                {t('our_team_text')}
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0", justifyContent: "center"}}>
                <div style={{ width: "190px" }}>
                  <FullButton title={t('read_more')} action={() => navigate("/about")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title={t('contact')} action={() => navigate("/contact")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 25%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  @media (max-width: 991px) {
    display: none;
  }
`;

const AddRight = styled.div`
  width: 50%;
  float: right;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height:100%;
  @media (max-width: 991px) {
    display: none;
  }
`;


const styles = {
  h1: {
    textAlign: "center",
  },
};