import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import FullButton from "../Buttons/FullButton";
import ContactUs from "../../assets/img/contact_us.jpg";

export default function ContactSection() {
  const { t, i18n } = useTranslation();
  const mailRef = useRef(null);
  const messageRef = useRef(null);
  const [status, setStatus] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function submit(event) {
    event.preventDefault();
    setStatus("");  // Clear any previous status messages

    if (!agreedToTerms) {
      setStatus(t("error_terms"));  
      return;
    }
    const submitButton = document.getElementById('submitButton');
    submitButton.disabled = true;
    submitButton.innerHTML = t("submit");

    const mail = mailRef.current.value;
    const message = messageRef.current.value;

    if (!validateEmail(mail)) {
      setStatus(t("error_mail"));
      return;
    }

    try {
      const response = await fetch(
        "https://7p139ij7f4.execute-api.eu-central-1.amazonaws.com/nmi/contact",
        {
          method: "POST",
          body: JSON.stringify({
            mail,
            message,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const r = await fetch(
          "https://7p139ij7f4.execute-api.eu-central-1.amazonaws.com/nmi/notify",
          {
            method: "POST",
            body: JSON.stringify({
              mail,
              message,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (r.ok) {
          console.log("Message sent");
          setStatus(t("success_sent"));
        } else {
          console.log("Notification failed!");
          setStatus(t("notif_failed"));
        }
      } else {
        console.log("Submission failed!");
        setStatus(t("submission_failed"));
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus(t("error"));
    }
    submitButton.disabled = false;
    submitButton.innerHTML = t(`send_message`);
  }

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t(`get_in_touch`)}</h1>
            <p className="font13">
              {t(`here_to_help_1`)}
              <br />
              {t(`here_to_help_2`)}
            </p>
          </HeaderInfo>
          <div className="row" style={{ marginBottom: "120px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{height: "455px"}}>
              <Form onSubmit={submit}>
                <InputWrapper>
                  <label className="font13">{t(`email`)}</label>
                  <input
                    style={styles.inputs}
                    type="text"
                    id="mail"
                    name="mail"
                    className="font20 extraBold"
                    ref={mailRef}
                  />
                </InputWrapper>
                <InputWrapper>
                  <label className="font13">{t(`message`)}</label>
                  <MessageTextArea
                    id="message"
                    name="message"
                    className="font20 extraBold"
                    ref={messageRef}
                    rows="5"
                  />
                </InputWrapper>
                <div className="checkbox_wrapper" style={styles.checkbox_wrapper}>
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    name="termsCheckbox"
                    style={styles.checkbox}
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                  />
                  <label htmlFor="termsCheckbox" className="font13">
                    {t(`agree`)} <a href="/Terms and Conditions.pdf" download="Terms and Conditions.pdf">{t(`terms`)}</a>
                  </label>
                </div>
                <SumbitWrapper className="flex" style={{justifyContent: "flex-start", maxWidth: "500px"}}>
                  <FullButton
                    type="submit"
                    title={t(`send_message`)}
                    className="pointer animate radius8"
                    id="submitButton"
                  />
                </SumbitWrapper>
              </Form>
              <p className="font13">{status}</p> {/* Status message displayed here */}
            </div>
            <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{height: "455px"}}>
              <Img className="radius8" src={ContactUs} alt="office" style={{zIndex: 9}} />
            </ImageWrapper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: 1px solid #feb343;
    margin-bottom: 30px;
    box-sizing: border-box;
  }
  textarea {
    min-height: 100px;
    resize: none;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
  input[type="checkbox"] {
    margin-right: 10px;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const MessageTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #feb343;
  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 991px) {
    display: none;
  }
`

const Img = styled.img`
  width: 100%;
  height:100%;
`;

const styles = {
  inputs: {
    color: "#feb343",
  },
  checkbox_wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "30px",
  },
  checkbox: {
    width: "15px",
    marginBottom: "0px",
    marginTop: "0px",
  },
};
