import React, { useState, useEffect } from 'react';
import FullButton from "../Buttons/FullButton";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const InfoCard = ({ title, imageSrc, texts }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
    };
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const styles = {
        mainContainer: {
          width: '75%',
          display: 'block',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          backgroundColor: 'var(--primary-20)',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          maxWidth: '800px',
          margin: '0 auto',
          transform: 'translate(0px, 100px)',
        },
        cardContainer: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          maxWidth: '800px',
          margin: '0 auto',
          transform: 'translate(0px, 20px)',
          textAlign: 'left',
        },
        textContainer: {
          flex: '1',
          paddingRight: isMobile ? '0' : '20px', // Remove padding on mobile
          paddingTop: isMobile ? '20px' : '0', // Add space between image and text on mobile
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'left',
        },
        title: {
          marginBottom: '10px',
          textAlign: 'center',
        },
        paragraph: {
          lineHeight: '1.5',
          marginBottom: '10px',
        },
        button: {
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: 'transparent',
          border: '2px solid #000',
          borderRadius: '5px',
          cursor: 'pointer',
        },
        imageContainer: {
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: isMobile ? '20px' : '0', // Add space below image on mobile
        },
        image: {
          maxWidth: '100%',
          borderRadius: '10px',
        },
    };
  return (
    <div style={styles.mainContainer}>
      <h2 className="font40" style={styles.title}>{title}</h2>
      <div style={styles.cardContainer}>
        <div style={styles.imageContainer}>
            <img src={imageSrc} alt="NMI Guard Security AI. EU AI ACT AUDITS" style={styles.image} />
        </div>
        <div style={styles.textContainer}>
            {texts.map((text, index) => (
                <p key={index} className={index%2!=0 ? "font13" : "font18 semiBold"} style={styles.paragraph}>
                {text}
                </p>
            ))}
            <FullButton title={t('contact')}  action={() => navigate('/contact')}/>
        </div>
      </div>
    </div>
  );
};


export default InfoCard;